export const environment = {
  API_URL: 'https://transportesmant.agd.com.ar/api_v2_stage',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyAj04JRKnehL9AhaT5TfQBYYt_kZSfBAIo',
    appId: '1:543072425672:web:3581b0888161369ea0b7fd',
    authDomain: 'agd-transportes-79744.firebaseapp.com',
    databaseURL: 'https://agd-transportes-79744.firebaseio.com',
    measurementId: 'G-ZYFRHLN88D',
    messagingSenderId: '543072425672',
    projectId: 'agd-transportes-79744',
    storageBucket: 'agd-transportes-79744.appspot.com',
    vapidKey:
      'BHLcl9Lx4-u39K68jKZt8D5-5ioFM_56uJ5VjJ57pxxkL7I2YgZ5rcvJFBSUfbWmnzA3ROK4ROYTjC9PH9Xi1wU',
  },
  GOOGLE_CLIENT_ID:
    '319550907028-m0l01j9usit28o9indpvnujovbg6c0oe.apps.googleusercontent.com',
  LOGIN_SEND_CODE_TIME: 60,
  MICROSOFT_CLIENT_ID: 'f3472785-c409-4d98-a54e-87e361f24029',
  MICROSOFT_LOGIN_SETTING: {
    authority: 'https://login.microsoftonline.com/common',
    logout_redirect_uri: 'https://transportesmant.agd.com.ar/auth/login',
    redirect_uri: 'https://transportesmant.agd.com.ar',
  },
  RECAPTCHA_V3_SITE_KEY: '6LfGcB4pAAAAAB7uBiOek8WhW2K3N9m52vc8NRmZ',
  SOCKET_URL: 'https://transportesmant.agd.com.ar',
  production: true,
};
